.etat-lieux-template {
	background-color: transparent !important;

	.disabled {
		pointer-events: none;
	}

	.etat-vehicule {
		.line-container {
			display: flex;
			justify-content: space-around;
			align-items: center;
			text-align: center;

			.el-template-container {
				width: 190px;
				height: 350px;
				border: 2px solid $cds-color-border-neutral-decorative;
				cursor: pointer;
				border-radius: 6px;
				background-repeat: no-repeat;
				background-size: 98% 98%;
				background-position: center;
				display: flex;

				.el-items-container {
					display: flex;
					flex-direction: column;
					flex: 1;

					div.el-item {
						border: 2px solid $cds-color-border-neutral-decorative;
						display: flex;
						margin: 1px;
						border-radius: 6px;
					}
				}
			}

			&.exterieur {
				.el-template-container {
					background-image: url('../../../assets/layout/vehicule/etatLieuxExterieur.png');

					.el-items-container {
						&.QUATRE_ZONES {
							& > .top {
								flex: 1;
							}

							& > .middle {
								flex: 2;
								flex-direction: row;
								display: flex;

								& > div.el-item {
									flex: 1;
								}
							}

							& > .bottom {
								flex: 1;
							}
						}

						&.HUIT_ZONES {
							& > .top {
								flex: 1;
							}

							& > .middle-top {
								flex: 1;
								flex-direction: row;
								display: flex;

								& > div.el-item {
									flex: 1;
								}
							}

							& > .middle {
								flex: 1;
								flex-direction: row;
								display: flex;

								& > div.el-item {
									flex: 1;
								}
							}

							& > .middle-bottom {
								flex: 1;
								flex-direction: row;
								display: flex;

								& > div.el-item {
									flex: 1;
								}
							}

							& > .bottom {
								flex: 1;
							}
						}
					}
				}
			}

			&.interieur {
				.el-template-container {
					background-image: url('../../../assets/layout/vehicule/etatLieuxInterieur.png');

					.el-items-container {
						&.TROIS_ZONES {
							& > .top {
								flex: 10;
							}

							& > .middle {
								flex: 24;
							}

							& > .bottom {
								flex: 6;
							}
						}

						&.QUATRE_ZONES {
							& > .top {
								flex: 10;
							}

							& > .middle-top {
								flex: 6;
							}

							& > .middle-bottom {
								flex: 18;
							}

							& > .bottom {
								flex: 6;
							}
						}
					}
				}
			}

			.selected {
				background-color: $cds-color-border-neutral-decorative;
			}
		}
	}

	.detail {
		ul.liste-details {
			padding-left: 0;
			list-style: none;

			div.detail-item {
				display: flex;
				justify-content: space-between;
				flex-direction: row;
				width: 100%;
				padding: 10px;
				border-bottom: solid 1px rgba($cds-color-border-neutral-decorative,0.05);
				align-items: center;
				height: 46px;

				.checkbox {
					margin-top: 5px;
					margin-bottom: 5px;
					margin-left: 10px;

					@media (min-width: $cds-size-breakpoint-sm) {
						display: inline-block;
						margin-top: 0;
						margin-bottom: 0;
					}
				}
			}

			ul {
				list-style: none;
			}
		}

		.checkbox {
			label {
				position: relative;
				padding-left: 30px;
			}

			input {
				z-index: 1;
				top: 0;
				left: 0;
				width: 30px;
				height: 30px;
				margin-top: 0;
				margin-left: 0 !important;
				cursor: pointer;
				opacity: 0;

				&:checked + .input-circle-helper {
					&:before {
						transform: scale(0);
					}

					&:after {
						transform: scale(1);
						opacity: 1;
					}
				}
			}

			.input-circle-helper {
				font-style: normal;
				line-height: 30px;
				font-family: 'Material-Design-Iconic-Font';
				text-align: center;

				&.camera {
					&:before, &:after {
						content: '\e412';
						font-family: 'Material Icons Round';
					}
				}

				&.remarque {
					&:before, &:after {
						content: '\e26c';
						font-family: 'Material Icons Round';
					}
				}

				&.verification {
					&:before, &:after {
						content: '\e876';
						font-family: 'Material Icons Round';
					}
				}

				&.visible {
					&:before, &:after {
						content: '\f15c';
					}
				}

				&:before, &:after {
					position: absolute;
					top: -3px;
					width: 30px;
					height: 30px;
					border: none;
					border-radius: 50%;
					backface-visibility: hidden;
					@include transition(all);
					@include transition-duration(250);
				}

				&:before {
					left: 0;
					background-color: rgba($cds-color-border-neutral-decorative,0.05);
					color: $cds-color-text-neutral-high-emphasis;
				}

				&:after {
					left: -1px;
					transform: scale(0) rotate(80deg);
					background-color: $cds-color-action-primary-active;
					color: $cds-color-action-white-active;
					opacity: 0;
				}
			}
		}

		.disabled {
			cursor: default;
		}

		.empty-message {
			text-align: center;
			padding: 20px;
		}

		.section {
			margin-top: 40px;
		}
	}
}